<template>
	<Transition
		name="modal"
		enter-active-class="transition-opacity duration-300 ease-in-out"
		leave-active-class="transition-opacity duration-300 ease-in-out"
		enter-from-class="opacity-0"
		leave-to-class="opacity-0"
	>
		<div
			v-if="modalOpen"
			class="fixed left-0 top-0 z-[60] flex h-full w-full bg-black/50 p-8"
			@click.self="emit('modalClose')"
		>
			<div
				ref="focusTarget"
				class="relative m-auto flex max-w-4xl flex-col items-center justify-center gap-4 rounded-xl bg-white p-5 text-center"
				:class="maxHeight"
			>
				<button
					:class="[
						'absolute -right-6 -top-6 size-6 rounded-2xl bg-white p-0.5',
						'transition duration-200 hover:scale-105',
						'focus-visible:ring focus-visible:ring-primary',
					]"
					@click="emit('modalClose')"
				>
					<IconsClose />
				</button>
				<slot></slot>
			</div>
		</div>
	</Transition>
</template>

<script setup lang="ts">
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap.mjs';

interface Props {
	modalOpen: boolean;
	maxHeight?: string;
}
const props = withDefaults(defineProps<Props>(), { maxHeight: '' });
const emit = defineEmits<{ (eventName: 'modalClose'): void }>();

const focusTarget = ref<HTMLElement | null>(null);
const { activate, deactivate } = useFocusTrap(focusTarget);
watch(
	() => props.modalOpen,
	async (newValue) => {
		if (newValue) {
			await nextTick();
			activate();
		} else if (!newValue) {
			deactivate();
		}
	},
);
</script>
